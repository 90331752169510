<template>
<div>
<loading :active.sync="isLoading"></loading>
<Header />
<div id="kv">
  <h1>404 Not Found</h1>
</div>
	<main>
	  <section class="content page_faq">
    <div class="container">
      	<h2>お探しのページが<br class="sp">見つかりませんでした。</h2>
	<p style="text-align: center;">お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。</p>
    </div>
  </section>
</main>
<Footer />
</div>
</template>

<script>
import Footer from '../footer'
import Header from '../header'
import Loading from 'vue-loading-overlay'

export default {
  data: function () {
    return {
      currentView: '',
      isLoading: false
    }
  },
  components: {
    Footer,
    Header,
    Loading
  }
}
</script>
